<template>
  <base-layout>
    <details-page-title
      title="Org User"
      :details-id="id"
      @activate="handleActivating()"
      @deactivate="handleDeactivating()"
    >
      <!-- <status-dropdown
        :endpoint="getStatusEndpoint"
        method="PUT"
        :statusCurrent="getStatus"
        statusKeyActivate="user_status"
        statusValueActivate="A"
        statusKeyDeactivate="user_status"
        statusValueDeactivate="D"
        :statusFirstEnabled="true"
        statusKeyFirst="user_status"
        statusValueFirst="H"
      /> -->
      <button
        @click="showModal"
        v-if="isAgentUser"
        class="flex items-center justify-start w-40 px-2 py-2 text-sm text-orange-400 border border-gray-200 rounded-full shadow-lg focus:outline-none backround-white"
      >
        <img src="@/assets/img/icons/topup.svg" alt="" class="w-8 h-8 mr-2" />
        <span>Topup</span>
      </button>
    </details-page-title>

    <details-page-header
      v-if="isAgentUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :agentCardInfo="header.summary"
      :symbol="symbol"
      :tabs="agentTabs"
    />
    <details-page-header
      v-else-if="isFranchiseUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :franchiseCardInfo="header.summary"
      :symbol="symbol"
      :tabs="franchiseTabs"
    />
    <details-page-header
      v-else-if="isOperatorUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :operatorCardInfo="header.summary"
      :symbol="symbol"
      :tabs="operatorTabs"
    />
    <details-page-header
      v-else-if="isOwnerUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :tabs="userTabs"
    />

    <details-page-header
      v-else
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :tabs="genericTabs"
    />
    <div class="min-h-screen">
      <router-view />
    </div>

    <t-modal v-model="topUpModal" class="topup-modal">
      <div class="p-8 modal-content">
        <h1 class="font-bold text-center text-gray-800 uppercase text-md">
          Transfer Balance to Agent
        </h1>

        <div class="mt-8 modal-input">
          <input
            type="text"
            v-model="balanceToTransfer"
            class="w-full h-16 px-3 text-2xl font-bold text-center text-orange-400 border rounded focus:outline-none"
            :placeholder="`e.g. ${symbol} 12.0`"
          />
          <h6 class="text-sm font-bold text-center text-gray-800">
            Amount to transfer
          </h6>
        </div>

        <div class="mt-5 single-input">
          <label class="w-full text-sm font-bold text-gray-800"
            >Current Balance</label
          >
          <input
            type="text"
            class="w-full px-2 py-2 text-gray-500 bg-gray-200 border-gray-300 rounded fborder"
            :value="`${header.profile.balance}`"
            readonly
          />
        </div>

        <div class="mt-5 single-input">
          <label class="w-full text-sm font-bold text-gray-800"
            >Transfer To</label
          >
          <input
            type="text"
            class="w-full px-2 py-2 text-gray-500 bg-gray-200 border border-gray-300 rounded"
            :value="header.full_name"
            readonly
          />
        </div>

        <!-- <div class="grid grid-cols-1">
          <label class="text-sm font-bold text-gray-800">Remarks</label>
          <textarea
            rows="10"
            class="h-20 px-2 py-2 text-gray-500 bg-white border border-gray-300 rounded"
            value="Test"
          ></textarea>
        </div> -->
        <div class="flex justify-center mt-8 modal-content-footer">
          <button
            class="py-3 mr-2 bg-gray-200 border border-gray-100 rounded cancel"
            @click="topUpModal = false"
          >
            Cancel
          </button>
          <button
            class="py-3 bg-orange-500 border border-gray-100 rounded confirm"
            @click="handleAgentTopup"
          >
            Transfer
          </button>
        </div>
      </div>
    </t-modal>
  </base-layout>
</template>

<script>
// import { StatusConfig } from '@/config/StatusConfig'
// import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import { AgentUserConfig } from '@/config/AgentUserConfig'
import BaseLayout from '@/views/shared/BaseLayout'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'
// import StatusDropdown from "@/components/dropdown/StatusDropdown";
import { mapGetters } from 'vuex'
import xMan from '@/utils/xMan'

export default {
  name: 'ViewOrganizationUser',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    // StatusDropdown
  },
  data() {
    return {
      // Requset states
      reqLoading: false,
      reqLoaded: false,
      reqFailed: false,
      reqFailures: null,

      symbol: '$',
      balanceToTransfer: null,
      org_user: {},
      fallbackText: '--',
      topUpModal: false,
      header: {
        address_info: {
          current_address_country: '',
          current_address_full: '',
          current_address_zip_code: '',
          permanent_address_country: '',
          permanent_address_full: '',
          permanent_address_zip_code: '',
        },
        assigned_fleets: [],
        document_info: '',
        email: '',
        full_name: '',
        id: '',
        organization_id: '',
        payment_info: {
          account_name: '',
          account_number: '',
          branch_name: '',
          country: '',
          id: '',
          routing_number: '',
        },
        phone_number: '',
        profile: {
          birthday: '',
          gender: '',
          profile_pic: '',
          ranger_job_type: '',
        },
        role: {},
        user_status: '',
      },
      userTabs: [
        { text: 'Basic Info', href: this.getTabHref(`profile`) },
        { text: 'Vehicles', href: this.getTabHref(`vehicles`) },
        { text: 'Payouts', href: this.getTabHref(`payouts`) },
        {
          text: 'Operational Areas',
          href: this.getTabHref(`operational-areas`),
        },
        { text: 'Tasks', href: this.getTabHref(`tasks`) },
        { text: 'Users', href: this.getTabHref(`users`) },
        { text: 'Revenues', href: this.getTabHref(`revenues`) },
        { text: 'Riders', href: this.getTabHref(`riders`) },
      ],
      operatorTabs: [
        { text: 'Basic Info', href: this.getTabHref(`profile`) },
        { text: 'Tasks', href: this.getTabHref(`tasks`) },
        { text: 'Logs', href: this.getTabHref(`logs`) },
      ],
      agentTabs: [
        { text: 'Basic Info', href: this.getTabHref(`profile`) },
        { text: 'Topup History', href: this.getTabHref(`topup`) },
        { text: 'Incoming Transaction', href: this.getTabHref(`incoming`) },
      ],
      franchiseTabs: [
        { text: 'Basic Info', href: this.getTabHref(`profile`) },
        { text: 'Fleets', href: this.getTabHref(`fleets`) },
        { text: 'Vehicles', href: this.getTabHref(`vehicles`) },
        { text: 'Riders', href: this.getTabHref(`riders`) },
        { text: 'Rides', href: this.getTabHref(`rides`) },
        { text: 'Revenues', href: this.getTabHref(`revenues`) },
        // { text: 'Payments', href: this.getTabHref(`payments`) },
        // { text: 'Debit', href: this.getTabHref(`debit`) },
        // { text: 'Credit', href: this.getTabHref(`credit`) },
        { text: 'Reports', href: this.getTabHref(`reports`) },
      ],
      genericTabs: [{ text: 'Basic Info', href: this.getTabHref(`profile`) }],
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    isAgentUser() {
      return this.header.role.role_name == 'Agent' ? true : false
    },
    isFranchiseUser() {
      return this.header.role.role_name == 'Franchise' ? true : false
    },
    isOperatorUser() {
      return this.header.role.role_name == 'Operator' ? true : false
    },
    isOwnerUser() {
      return this.header.role.role_name == 'Owner' ? true : false
    },
    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header ? this.header.full_name : ''
    },
    getBadge() {
      return this.header ? this.header.role.role_name : ''
    },
    getAvatar() {
      return this.header ? this.header.profile.profile_pic : ''
    },
    getEmail() {
      return this.header ? this.header.email : ''
    },
    getPhone() {
      return this.header ? this.header.phone_number : ''
    },
    getWebsite() {
      return this.header ? this.header.website_link : ''
    },
    getStatus() {
      return this.header && this.header.user_status === 'A' ? 'A' : 'D'
    },
    // getStatusEndpoint() {
    //   return StatusConfig.api.operator(this.id)
    // },
  },
  async created() {
    await this.loadUserData()
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'

    // todo: fix
    // this.$router.push({ name: 'ViewOrganizationUserProfile' })
  },
  methods: {
    async loadUserData() {
      this.reqLoading = true
      await this.$http
        .get(this.$config.orgUser.api.single(this.id))
        .then((res) => {
          console.log('org-user-header', res)
          this.header = res.data

          this.reqLoaded = true
          this.reqLoading = false
        })
        .catch((err) => {
          console.log('org-user-header-err', err)
          this.reqFailures = err.data
          this.reqFailed = true
        })
        .finally(() => {
          this.reqLoading = false
        })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/users/${this.$route.params.id}/${path}`
    },
    showModal() {
      return (this.topUpModal = !this.topUpModal)
    },
    async handleAgentTopup() {
      var recharge_amount = this.balanceToTransfer
      if (isNaN(recharge_amount)) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Enter valid amount to transact to agent',
          },
          5000
        )
        return
      }
      if (recharge_amount <= 0) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Amount must be greater than 0',
          },
          5000
        )
        return
      }
      // console.log("topup for user", this.header.id);
      const data = new xMan({
        recharge_amount: parseFloat(recharge_amount),
      }).toFormData()
      try {
        var transferBalanceResponse = await this.$http.post(
          AgentUserConfig.api.balanceTransfer(this.header.id),
          data
        )
        transferBalanceResponse = transferBalanceResponse.data
        console.log('Agent Topup Fired : ', transferBalanceResponse)
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: transferBalanceResponse.details,
          },
          5000
        )
        this.topUpModal = false
        await this.loadUserData()
      } catch (error) {
        console.error(error)
        // if (!error.response.data) return
        // this.$notify(
        //   {
        //     group: "generic",
        //     type: "error",
        //     title: "Error",
        //     text: error.response.data.detail
        //   },
        //   5000
        // );
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  width: 480px;
  height: 545px;
  background: #fff;
  margin: 0 auto;
  &-footer {
    padding-top: 20px;
    button {
      min-width: 100px;
      height: 35px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      width: auto;
      padding: 5px 10px;
      &.cancel {
        color: #6a6a6a;
      }
      &.confirm {
        color: #fff;
      }
    }
  }
}

::v-deep {
  .topup-modal {
    .max-w-3xl {
      max-width: 480px;
    }
  }
}
.divider {
  background: #e1e1e1;
  width: 1px;
  height: 100%;
  margin: 0 10px;
}
</style>
